<template>
  <label
    class="response-multiple-choice-option"
    :class="classes"
    :disabled="disabled"
    @click="animation = isChecked ? 'deactivate' : 'activate'"
    @animationend="animation = null"
  >
    <input
      v-model="model"
      class="input"
      type="checkbox"
      :disabled="disabled"
      :name="name"
      :value="value"
      :checked="checked"
    >
    <span class="label"><slot>{{ label }}</slot></span>
    <div class="badge">
      <base-icon
        name="checkmark-outline"
        :size="18"
      />
    </div>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    checked: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animation: null,
    };
  },
  computed: {
    model: {
      get() { return this.checked; },
      set(value) { this.$emit('input', value); },
    },
    isChecked() {
      return this.checked.includes(this.value);
    },
    classes() {
      return {
        'response-multiple-choice-option--checked': this.isChecked,
        'response-multiple-choice-option--disabled': this.disabled,
        'response-multiple-choice-option--activate': this.animation === 'activate',
        'response-multiple-choice-option--deactivate': this.animation === 'deactivate',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.response-multiple-choice-option {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0 16px;
  color: $color-white;
  font-size: 0;
  font-size: 14px;
  background-color: rgba($color-white, 0.2);
  border: solid 1px $color-primary5;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color, border-color, color, transform;
  transition-timing-function: ease;
  transition-duration: 200ms;
  user-select: none;

  .badge {
    position: absolute;
    top: -4px;
    right: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    color: #fff;
    line-height: 0;
    background-color: $color-success;
    border: solid 2px #fff;
    border-radius: 15px;
    opacity: 0;
    transition: opacity 50ms ease;
  }

  &--checked {
    color: $color-text;
    background-color: $color-white;
    border-color: $color-white;

    .badge {
      opacity: 1;
      transition: opacity 100ms ease;
    }
  }

  &--disabled {
    color: $color-gray50;
    background-color: $color-gray50;
    cursor: auto;
    pointer-events: none;
  }

  &--activate {
    animation: zoom-in 300ms ease;
  }

  &--deactivate {
    animation: zoom-out 300ms ease;
  }
}

.input {
  display: none;
}

.label {
  pointer-events: none;
}

@keyframes zoom-in {
  0% { transform: scale(1); }
  20% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes zoom-out {
  0% { transform: scale(1); }
  20% { transform: scale(0.9); }
  100% { transform: scale(1); }
}
</style>
