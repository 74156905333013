<template>
  <base-page
    class="pulse"
    :loading="isloading"
    type="dark"
    fullscreen
  >
    <template #menu>
      <base-button
        v-if="isAuthenticated || error"
        class="button"
        label="Return"
        :to="{ name: 'home' }"
        size="small"
        type="ghost-inverted"
        icon="arrow-back-outline"
      />
    </template>
    <template #default>
      <!-- ERROR STATE -->
      <base-graphic
        v-if="error"
        :image="require('@/assets/images/graphic/waiting.svg')"
        :title="error.title"
        :body="error.message"
      />
      <!-- DEFAULT STATE -->
      <template v-else-if="pulse">
        <base-tag
          v-if="pulse.category"
          class="category"
          type="secondary"
        >
          {{ pulse.category }}
        </base-tag>
        <h1 class="question">
          {{ pulse.question }}
        </h1>
        <div class="response">
          <response-agree-disagree
            v-if="pulse.type === 1"
            v-model="answer"
            name="pulse"
          />
          <response-multiple-choice
            v-if="pulse.type === 2"
            v-model="answer"
            name="pulse"
            :options="pulse.options"
          />
          <response-rating-scale-5-point
            v-if="pulse.type === 3"
            v-model="answer"
            name="pulse"
          />
          <response-rating-scale-10-point
            v-if="pulse.type === 4"
            v-model="answer"
            name="pulse"
          />
        </div>
        <base-button
          class="submit"
          :label="hasAnswered ? 'Confirm' : 'Please answer to continue'"
          type="secondary"
          size="large"
          full-width
          :disabled="!hasAnswered"
          :loading="isloading"
          @click="submit"
        />
      </template>
    </template>
  </base-page>
</template>

<script>
import ResponseAgreeDisagree from '@/components/response/ResponseAgreeDisagree.vue';
import ResponseMultipleChoice from '@/components/response/ResponseMultipleChoice.vue';
import ResponseRatingScale5Point from '@/components/response/ResponseRatingScale5Point.vue';
import ResponseRatingScale10Point from '@/components/response/ResponseRatingScale10Point.vue';

export default {
  components: {
    ResponseAgreeDisagree,
    ResponseMultipleChoice,
    ResponseRatingScale5Point,
    ResponseRatingScale10Point,
  },
  data() {
    return {
      key: this.$route.query.key,
      answer: this.$route.query.answer,
      error: null,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    isloading() {
      return this.$store.getters['pulse/isLoading'];
    },
    pulse() {
      return this.$store.getters['pulse/getPulse'];
    },
    hasAnswered() {
      if (Array.isArray(this.answer)) {
        return !!this.answer.length;
      }
      return !!this.answer;
    },
  },
  created() {
    this.saveKey();
    this.getPulse();
  },
  methods: {
    saveKey() {
      this.$store.commit('pulse/SET_KEY', this.key);
    },
    getPulse() {
      this.$store.dispatch('pulse/fetchPulse').then(() => {
        // Pulse fetched, get company details
        this.getCompany();
      }).catch(() => {
        // Fetching categories failed
        this.error = {
          title: 'Pulse not found',
          message: 'Maybe you have already answered this pulse before?',
        };
      });
    },
    getCompany() {
      this.$store.dispatch('company/fetchCompany', {
        pulseKey: this.key,
      });
    },
    submit() {
      if (!this.hasAnswered) return;
      // Submit answers
      this.$store.dispatch('pulse/submitPulse', this.answer).then(() => {
        // Refresh data
        this.$emit('refresh');
        // Move to responded route
        this.$router.push({ name: 'responded' });
      }).catch(() => {
        // Submitting answer failed
        this.error = {
          title: 'Response failed',
          message: 'Your response could not be saved. Please try again!',
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pulse {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category {
  flex-shrink: 0;
  align-self: flex-start;
  margin-bottom: 16px;
}

.question {
  margin: 0 0 24px;
  font-weight: bold;
  font-size: 36px;
  font-family: $font-family-title;
  line-height: 1.1em;
}

.response {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
}

.preload {
  display: none;
}
</style>
