<template>
  <div class="response-rating-scale10">
    <div class="options">
      <response-rating-scale-option
        v-for="option in options"
        :key="option"
        v-model="selected"
        class="option"
        :value="option"
        :checked="option === selected"
        :name="name"
        size="small"
        @click="$emit('click')"
      >
        <div class="option-label">
          {{ option }}
        </div>
      </response-rating-scale-option>
    </div>
    <div
      v-if="slider"
      class="slider"
    >
      <response-slider
        v-model="selected"
        :min="1"
        :max="options.length"
      />
    </div>
  </div>
</template>

<script>
import ResponseSlider from '@/components/response/ResponseSlider.vue';
import ResponseRatingScaleOption from '@/components/response/ResponseRatingScaleOption.vue';

export default {
  components: {
    ResponseSlider,
    ResponseRatingScaleOption,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    answers: {
      type: Array,
      default: () => [],
    },
    slider: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      selected: null,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.selected = value;
      },
      immediate: true,
    },
    selected(selected) {
      this.$emit('input', selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.response-rating-scale10 {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.options {
  display: flex;
  justify-content: space-between;
  margin: 32px 0 24px;
  padding-top: 60px;
}

.option {
  flex-shrink: 0;
  margin-right: -40px;

  &:nth-child(odd) {
    top: -60px;
  }
}

.option-label {
  font-weight: bold;
  font-size: 20px;
}

.slider {
  margin-bottom: 48px;
  padding: 0 8px;
}
</style>
