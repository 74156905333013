<template>
  <div class="response-multiple-choice">
    <response-multiple-choice-option
      v-for="key in randomizedKeys"
      :key="key"
      v-model="selected"
      :value="key"
      :name="name"
      :label="options[key]"
    />
  </div>
</template>

<script>
import ResponseMultipleChoiceOption from '@/components/response/ResponseMultipleChoiceOption.vue';

export default {
  components: {
    ResponseMultipleChoiceOption,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    randomizedKeys() {
      // Create array with option keys
      const keys = Object.keys(this.options);
      // Shuffle keys
      for (let i = keys.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [keys[i], keys[j]] = [keys[j], keys[i]];
      }
      return keys;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selected = value;
      },
      immediate: true,
    },
    selected(selected) {
      this.$emit('input', selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.response-multiple-choice {
  align-self: self-start;
  margin-top: 16px;
  margin-right: 0 8px;
}
</style>
